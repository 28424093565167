<template>
  <div
    class="w-full container flex flex-col md:flex-row items-center justify-between px-5 pt-20 text-gray-700"
  >
    <div class="max-w-xl">
      <div class="text-5xl text-left font-dark font-bold">404</div>
      <p class="text-2xl text-left md:text-3xl font-light leading-normal">
        Lo sentimos, no pudimos encontrar esta página.
      </p>
      <p class="mb-4 text-left">
        Pero no te preocupes, puedes encontrar lo que buscas volviendo a la
        página principal.
      </p>

      <router-link
        class="group relative flex justify-center py-2 px-8 border border-transparent text-sm font-medium rounded-md text-gray-50 bg-green-500 hover:bg-green-600 focus:outline-none"
        tag="button"
        to="/"
      >
        Volver
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: '404',
  data() {
    return {};
  },
};
</script>

<style scoped>
.error-link-group {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.error-link {
  cursor: pointer;
  color: #e5e7eb;
  text-decoration: underline;
}
.error-link:not(:last-child) {
  margin-right: 1em;
}
</style>
